import { ReactComponent as MorningImage } from 'assets/images/slot-icons/morning.svg';
import { ReactComponent as NoonImage } from 'assets/images/slot-icons/noon.svg';
import { ReactComponent as EveningImage } from 'assets/images/slot-icons/evening.svg';

export const APIMethodEnum = {
  GET: 'get',
  POST: 'post',
  DELETE: 'delete',
  PUT: 'put',
};

export const IconMapping = {
  error: '/images/message_failed.svg',
  add: '/images/add.svg',
  minus: '/images/minus.svg',
  sub_cre: '/images/subsc-created.svg',
  success: '/images/sucess.svg',
  warning: '/images/warning.svg',
  settings: '/images/warning.svg',
};

export const keyMapping = {
  group_id: 'Group',
  location_id: 'Location',
  partner_id: 'Partner',
  plan_package_id: 'Plan',
  plan_id: 'Plan',
  plan_website: 'Website',
  billing_npi: 'Billing NPI',
  ext_facility_id: 'Facility Id',
  first_name: 'First Name',
  last_name: 'Last Name',
  date_of_birth: 'DOB',
  gender: 'Gender',
  email: 'Email',
  phone: 'Phone',
  line1: 'Line 1',
  city: 'City',
  state: 'State',
  zipcode: 'Zipcode',
  full_name: 'Full Name',
  relationship: 'Relationship',
  title: 'Title',
  contact_email: 'Email',
  location_name: 'Location',
  region_id: 'Region',
  latitude: 'Latitude',
  longitude: 'Longitude',
};

export const GroupKeysMapping = {
  industry: 'Industry',
  patient_url_part: 'Domain Prefix',
  partner_id: 'Partner Id',
  plan_package_id: 'Plan',
  plan_id: 'Plan',
  first_name: 'First Name',
  last_name: 'Last Name',
  date_of_birth: 'DOB',
  gender: 'Gender',
  email: 'Email',
  phone: 'Phone',
  line1: 'Line 1',
  title: 'Title',
  contact_email: 'Email',
  city: 'City',
  state: 'State',
  zipcode: 'Zipcode',
  location_name: 'Location',
  group_name: 'Group Name',
  fee_schedule_desc: "Savings Summary UI Description",
  plan_summary_desc: "Plan Summary Description",
  vendor_name: 'Vendor',
  vendor_firmname: 'Firm Name',
  vendor: "Vendor",
  config_name: "Config Name",
  form_url: "Form Url",
};

export const PartnerKeyMapping = {
  industry: 'Industry',
  plan_website: 'Website',
  partner_name: 'Name',
  plan_package_id: 'Plan',
  plan_id: 'Plan',
  first_name: 'First Name',
  last_name: 'Last Name',
  date_of_birth: 'DOB',
  gender: 'Gender',
  email: 'Email',
  title: 'Title',
  phone: 'Phone',
  contact_email: 'Email',
  line1: 'Line 1',
  cognito_username: 'User Name',
  city: 'City',
  state: 'State',
  zipcode: 'Zipcode',
  location_name: 'Location',
  partner_id: 'Partner Id',
  group_id: 'Group Id',
  region_id: 'Region Id',
  location_id: 'Location Id',
  region_name: 'Region Name',
  role: 'Role',
};

export const SUBSTATUS = {
  Active: 'Active',
  Cancelled: 'Cancelled',
  Dispute: 'Dispute',
  Draft: 'Draft',
  Expired: 'Expired',
  'Overdue Expired': 'Overdue Expired',
  Onetime: 'One-time',
  Overdue: 'Overdue',
  Pause: 'Paused',
  'Pause Pending': 'Pause Pending',
  Prepaid: 'Early Renewed',
};

export const PAYMENT_STATUS = {
  paid: 'Paid',
  void: 'Void',
  dispute: 'Dispute',
  open: 'Pending',
  wait: 'Pending',
  fail: 'Failed',
  refunded: 'Refunded',
  refund_pending: 'Refund Pending',
  partially_refunded: 'Partially Refunded',
};

export const ORDER_STATUS = {
  Open: 'Open',
  Fail: 'Failed',
  Active: 'Active',
  Complete: 'Complete',
  Paid: 'Paid',
  Pending: 'Pending',
  Overdue: 'Overdue',
  Cancel: 'Cancelled',
};

export const HTTP_STATUS_CODES = {
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  203: 'Non-Authoritative Information',
  204: 'No Content',
  205: 'Reset Content',
  206: 'Partial Content',
  300: 'Multiple Choices',
  301: 'Moved Permanently',
  302: 'Found',
  303: 'See Other',
  304: 'Not Modified',
  305: 'Use Proxy',
  307: 'Temporary Redirect',
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Request Entity Too Large',
  414: 'Request-URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Requested Range Not Satisfiable',
  417: 'Expectation Failed',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
};

export const STATES = [
  {
    name: 'Alabama',
    code: 'AL',
  },
  {
    name: 'Alaska',
    code: 'AK',
  },
  {
    name: 'Arizona',
    code: 'AZ',
  },
  {
    name: 'Arkansas',
    code: 'AR',
  },
  {
    name: 'California',
    code: 'CA',
  },
  {
    name: 'Colorado',
    code: 'CO',
  },
  {
    name: 'Connecticut',
    code: 'CT',
  },
  {
    name: 'Delaware',
    code: 'DE',
  },
  {
    name: 'Florida',
    code: 'FL',
  },
  {
    name: 'Georgia',
    code: 'GA',
  },
  {
    name: 'Hawaii',
    code: 'HI',
  },
  {
    name: 'Idaho',
    code: 'ID',
  },
  {
    name: 'Illinois',
    code: 'IL',
  },
  {
    name: 'Indiana',
    code: 'IN',
  },
  {
    name: 'Iowa',
    code: 'IA',
  },
  {
    name: 'Kansas',
    code: 'KS',
  },
  {
    name: 'Kentucky',
    code: 'KY',
  },
  {
    name: 'Louisiana',
    code: 'LA',
  },
  {
    name: 'Maine',
    code: 'ME',
  },
  {
    name: 'Maryland',
    code: 'MD',
  },
  {
    name: 'Massachusetts',
    code: 'MA',
  },
  {
    name: 'Michigan',
    code: 'MI',
  },
  {
    name: 'Minnesota',
    code: 'MN',
  },
  {
    name: 'Mississippi',
    code: 'MS',
  },
  {
    name: 'Missouri',
    code: 'MO',
  },
  {
    name: 'Montana',
    code: 'MT',
  },
  {
    name: 'Nebraska',
    code: 'NE',
  },
  {
    name: 'Nevada',
    code: 'NV',
  },
  {
    name: 'New Hampshire',
    code: 'NH',
  },
  {
    name: 'New Jersey',
    code: 'NJ',
  },
  {
    name: 'New Mexico',
    code: 'NM',
  },
  {
    name: 'New York',
    code: 'NY',
  },
  {
    name: 'North Carolina',
    code: 'NC',
  },
  {
    name: 'North Dakota',
    code: 'ND',
  },
  {
    name: 'Ohio',
    code: 'OH',
  },
  {
    name: 'Oklahoma',
    code: 'OK',
  },
  {
    name: 'Oregon',
    code: 'OR',
  },
  {
    name: 'Pennsylvania',
    code: 'PA',
  },
  {
    name: 'Rhode Island',
    code: 'RI',
  },
  {
    name: 'South Carolina',
    code: 'SC',
  },
  {
    name: 'South Dakota',
    code: 'SD',
  },
  {
    name: 'Tennessee',
    code: 'TN',
  },
  {
    name: 'Texas',
    code: 'TX',
  },
  {
    name: 'Utah',
    code: 'UT',
  },
  {
    name: 'Vermont',
    code: 'VT',
  },
  {
    name: 'Virginia',
    code: 'VA',
  },
  {
    name: 'Washington',
    code: 'WA',
  },
  {
    name: 'West Virginia',
    code: 'WV',
  },
  {
    name: 'Wisconsin',
    code: 'WI',
  },
  {
    name: 'Wyoming',
    code: 'WY',
  },
];

export const MINOR_AGE = 16;
export const STRIPE_CONTACT_MIN_AGE = 13;

export const CreditCardMapping = {
  visa: 'Visa',
  mastercard: 'Master Card',
  discover: 'Discover',
  amex: 'American Express',
};

export const PLAN_BILLING_FREQUENCIES = [
  {
    name: 'Annual',
    value: 'Annual',
    multiplier: 1,
  },
  {
    name: 'Annual Billed Monthly',
    value: 'Annual Billed Monthly',
    multiplier: 12,
  },
];

export const PLAN_PROCESS_FREQUENCIES = {
  annual_billed_monthly: 'Annual Billed Monthly',
  annual: 'Annual',
};

export const PLAN_DISPLAY_FREQUENCIES = {
  annual_billed_monthly: 'Billed Monthly',
  annual: 'Billed Yearly',
};

export const DISPLAY_BILLING_FREQUENCY = {
  Annual: 'year',
  Biennial: '2 year',
  'Annual Billed Semi-Annual': 'halfearly',
  'Annual Billed Quarterly': 'quarterly',
  'Annual Billed Monthly': 'month',
  Monthly: 'month',
};

export const MEMBER_TYPE = [
  {
    key: 'Primary',
    value: 'Primary',
  },
  {
    key: 'Dependent',
    value: 'Dependent',
  },
  {
    key: 'General',
    value: 'General',
  },
];

export const TIER_MEMBER_TYPE = [
  {
    key: 'Tiered',
    value: 'Tier',
  },
];

export const PAYMENT_CYCLES = [
  {
    id: 3,
    name: '3 Months',
  },
  {
    id: 6,
    name: '6 Months',
  },
  {
    id: 9,
    name: '9 Months',
  },
  {
    id: 12,
    name: '12 Months',
  },
];

export const STRIPE_FEE_PAID_BY = {
  subscriber: 'Paid by Subscriber',
  subscribili: 'Paid by Subscribili',
  provider: 'Paid by Provider',
};

export const MONTHS_MAPPING = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

export const MONTHS = [
  {
    value: '01',
    label: 'January',
  },
  {
    value: '02',
    label: 'February',
  },
  {
    value: '03',
    label: 'March',
  },
  {
    value: '04',
    label: 'April',
  },
  {
    value: '05',
    label: 'May',
  },
  {
    value: '06',
    label: 'June',
  },
  {
    value: '07',
    label: 'July',
  },
  {
    value: '08',
    label: 'August',
  },
  {
    value: '09',
    label: 'September',
  },
  {
    value: '10',
    label: 'October',
  },
  {
    value: '11',
    label: 'November',
  },
  {
    value: '12',
    label: 'December',
  },
];

export const FIRM_NAME_MAPPING = {
  modmed: 'modmed_firm_name',
  nexhealth: 'nexhealth_subdomain',
  'elation health': 'elationhealth_firm_name',
  opendental: 'opendental_firm_name',
  denticon: 'pms_firm_name',
  athena: 'pms_firm_name',
  carestack: 'pms_firm_name',
  ascend: 'pms_firm_name',
};

export const PRINT_QUANTITY = [25, 50, 75, 100];

export const INDUSTRIES = [
  'Dentistry',
  'Primary Care',
  'Dermatology',
  'Urgent care',
  'Veterinary',
];

export const BUSINESS_STRUCTURES = [
  {
    value: 'sole_proprietorship',
    label: 'Sole proprietorship',
  },
  {
    value: 'single_member_llc',
    label: 'Single-member LLC',
  },
  {
    value: 'multi_member_llc',
    label: 'Multi-member LLC',
  },
  {
    value: 'private_partnership',
    label: 'Private partnership',
  },
  {
    value: 'private_corporation',
    label: 'Private corporation',
  },
  // {
  //   value: '',
  //   label: 'Other/I\'m not sure'
  // }
];

export const SINGLE_OWNER_BUSINESS = [
  'sole_proprietorship',
  'single_member_llc',
];

export const ROLES_LIST = {
  superadmin: 'Superadmin',
  subscribili: 'Subscribili User',
  partner_admin: 'Partner Admin',
  partner_user: 'Partner User',
  group_admin: 'Group Admin',
  group_user: 'Group User',
  region_admin: 'Region Admin',
  region_user: 'Region User',
  location_admin: 'Location Admin',
  location_user: 'Location User',
};

export const GENDER_LIST = [
  {
    key: 'Male',
    value: 'Male',
  },
  {
    key: 'Female',
    value: 'Female',
  },
  {
    key: 'Others',
    value: 'Others',
  },
];

export const LANGUAGE_LIST = [
  {
    key: 'en',
    value: 'English',
  },
  {
    key: 'es',
    value: 'Spanish',
  },
];

export const BOOKING_STEPS = [
  {
    label: 'Service',
    description: 'What would you like to come in for?',
  },
  {
    label: 'Date & Time',
    description: 'Select provider, the date & time you’re looking for',
  },
  {
    label: 'Confirm',
    description: 'Appointment Summary',
  },
];

export const SUBSCRIBER_IMPORT_STEPS = [
  {
    label: 'Upload File',
  },
  {
    label: 'Review File',
  },
  {
    label: 'Add Subscribers',
  },
];

export const APPOINTMENT_SLOT_INTERVALS = [
  {
    key: 'morning',
    image: <MorningImage className="morning-image" />,
    title: 'Morning',
    timing: '8am - 12pm',
  },
  {
    key: 'noon',
    image: <NoonImage className="noon-image" />,
    title: 'Afternoon',
    timing: '12pm - 4pm',
  },
  {
    key: 'evening',
    image: <EveningImage className="evening-image" />,
    title: 'Evening',
    timing: '4pm - 7pm',
  },
];

export const STAT_CARDS = [
  {
    img: '/images/activeuser.svg',
    alt: 'Active User',
    title: 'Active User',
    short_title: 'Active',
    stat_key: 'Active',
  },
  {
    img: '/images/canceluser.svg',
    alt: 'Cancelled User',
    title: 'Cancelled User',
    short_title: 'Cancelled',
    stat_key: 'Cancelled',
  },
  {
    img: '/images/expireduser.svg',
    alt: 'Expired User',
    title: 'Expired User',
    short_title: 'Expired',
    stat_key: 'Expired',
  },
  {
    img: '/images/overdue.svg',
    alt: 'Overdue User',
    title: 'Overdue User',
    short_title: 'Overdue',
    stat_key: 'Overdue',
  },
  {
    img: '/images/cardexpiresoon.svg',
    alt: 'Card Expiring Soon',
    title: 'Card Expiring Soon',
    short_title: 'Card Expiring Soon',
    stat_key: 'card_expiring_soon',
  },
];

export const NOTE_MAX_LENGTH = 500;

export const MAX_CPT_ROWS = 6;

export const MAX_TOOLTIP_CPT_ROWS = 6;

export const CPT_STATUS_LIST = {
  true: 'Hidden',
  false: 'Active',
};

export const CPT_DISPLAY_LIST = {
  all: 'All',
  plan_card: 'Plan Card',
  fees_list: 'Fees Summary',
  hidden: 'Hidden',
};

export const FILTER_CHIPS_KEYMAPPING = {
  text: 'Keyword',
  flexpay: 'Flexpay',
  partner_id: 'Partner',
  group_id: 'Group',
  region_id: 'Region',
  location_id: 'Location',
  facility_id: 'Facility',
  plan_package_id: 'Plan Name',
  billing_frequency: 'Frequency',
  first_name: 'First Name',
  last_name: 'Last Name',
  email: 'Email',
  phone: 'Phone',
  patient_id: 'Patient Id',
  date_of_birth: 'DOB',
  created_end: 'Date Range',
  year: 'Year',
  statStatus: 'Status',
  overdue_days: 'Overdue',

  location: 'Location',
  legal_name: 'Legal Name',
  plan: 'Plan',
  frequency: 'Frequency',
  totalUsers: 'Total Users',
  dynamics: 'Dynamics',
};

export const OVERDUE_LIST = [
  {
    min: 7,
    max: 14,
    label: '7 - 14 Days',
    overdue_days: 7,
  },
  {
    min: 15,
    label: '14+ Days',
    overdue_days: 14,
  },
];

export const GLOBAL_SEARCH_SUGGESTIONS = [
  {
    key: 'first_name',
    value: 'First Name:',
    example: 'Ex. First Name:Joe',
    groupKey: 'Optional Filters',
  },
  {
    key: 'last_name',
    value: 'Last Name:',
    example: 'Ex. Last Name:Brown',
    groupKey: 'Optional Filters',
  },
  {
    key: 'email',
    value: 'Email:',
    example: 'Ex. Email:joe.brown@example.com',
    groupKey: 'Optional Filters',
  },
  {
    key: 'phone',
    value: 'Phone:',
    example: 'Ex. Phone:1234567890',
    groupKey: 'Optional Filters',
  },
  {
    key: 'patient_id',
    value: 'Patient Id:',
    example: 'Ex. Patient Id:12345',
    groupKey: 'Optional Filters',
  },
];

export const GLOBAL_SEARCH_MATCHES = {
  firstname: 'first_name',
  lastname: 'last_name',
  email: 'email',
  phone: 'phone',
  patientid: 'patient_id',
};

export const PAYMENT_TYPES = {
  Card: 'Credit & Debit Card',
};

// Payment Types
export const PAYMENT_TYPES_NAME = {
  Card: 'Card',
  Cash: 'Cash',
  Financed: 'Financed',
  HSA_FSA: 'HSA_FSA',
  ACH: 'ACH'
};

export const CHARGE_DATE = [
  { key: 1, value: '1st of month' },
  { key: 5, value: '5th of month' },
  { key: 10, value: '10th of month' },
];

export const CHARGE_LEVEL = [
  { key: 'group', value: 'Group' },
  { key: 'region', value: 'Region' },
  { key: 'location', value: 'Location' },
];

export const CARD_TYPE_MAPPING = {
  credit: 'Credit Card',
  debit: 'Debit Card',
  prepaid: 'Prepaid Card',
};

export const EmployerKeyMapping = {
  industry: 'Industry',
  plan_website: 'Website',
  employer_name: 'Name',
  plan_package_id: 'Plan',
  plan_id: 'Plan',
  first_name: 'First Name',
  last_name: 'Last Name',
  date_of_birth: 'DOB',
  gender: 'Gender',
  email: 'Email',
  title: 'Title',
  phone: 'Phone',
  contact_email: 'Email',
  line1: 'Line 1',
  cognito_username: 'User Name',
  city: 'City',
  state: 'State',
  zipcode: 'Zipcode',
  location_name: 'Location',
  partner_id: 'Partner Id',
  group_id: 'Group Id',
  region_id: 'Region Id',
  location_id: 'Location Id',
  region_name: 'Region Name',
  role: 'Role',
};

export const EMPLOYEE_STATUS = {
  invited: 'Invited',
  accepted: 'Accepted',
  ...SUBSTATUS,
};

export const NOTES_STATUS = {
  private: 'Private',
  live: 'Live',
};

export const PLAN_PACKAGE_TYPE = {
  Base: 'Base Plan',
  Addon: 'Add-on',
  Tiered: 'Tier',
};

export const ONBOARD_STAGE = {
  UPDATE_LOCATION: 'update_location',
  SELECT_PLANS: 'select_plans',
  PLAN_DISCOUNTS: 'plan_discounts',
  PLAN_SUMMARY: 'plan_summary',
  PAYOUT_INFORMATION: 'payout_information',
  AGREEMENT_PROGRESS: 'agreement_progress', // Need to recreate document
  PROVIDER_AGREEMENT: 'provider_agreement', // Document created
  COMPLETED: 'completed',
};

export const ONBOARDING_STEPS = [
  {
    key: ONBOARD_STAGE.UPDATE_LOCATION,
    name: 'Location Details',
    description: "Confirm the location info's here",
  },
  {
    key: ONBOARD_STAGE.SELECT_PLANS,
    name: 'Select Plan',
    description: 'Choose the plan',
  },
  {
    key: ONBOARD_STAGE.PLAN_DISCOUNTS,
    name: 'Plan Discounts',
    description: 'Configure the plan discounts',
  },
  {
    key: ONBOARD_STAGE.PLAN_SUMMARY,
    name: 'Plan Summary',
    description: 'Review selected plan info',
  },
  {
    key: ONBOARD_STAGE.PAYOUT_INFORMATION,
    name: 'Payout Information',
    description: 'Share basic business info',
  },
  {
    key: ONBOARD_STAGE.PROVIDER_AGREEMENT,
    alterKey: ONBOARD_STAGE.AGREEMENT_PROGRESS,
    name: 'Participating Provider Agreement',
    description: 'Review and sign terms and conditions',
  },
];

export const STRIPE_ONBOARD_STATUS = {
  NOT_APPLICABLE: 'not_applicable',
  PENDING: 'pending',
  COMPLETED: 'completed',
};

export const STRIPE_ONB_STATUS_LABEL = {
  not_applicable: 'Not Applicable',
  pending: 'Pending',
  completed: 'Completed',
};

export const DISCOUNT_TYPE = {
  NO_DISCOUNT: 'no_discount',
  DISCOUNT_PERCENT: 'discount_percent',
  FLAT_PRICE: 'flat_price',
  DISCOUNT_PRICE: 'discount_price',
};

export const DISCOUNT_LEVEL = {
  GLOBAL: 'global',
  CATEGORY: 'category',
};

export const AGREEMENT_STATUS = {
  NOT_FOUND: 'document.not_found',
  UPLOADED: 'document.uploaded',
  DRAFT: 'document.draft',
  COMPLETED: 'document.completed',
};

export const STATUS_LIST = [
  {
    key: 'Active',
    label: 'Active',
  },
  {
    key: 'Hidden',
    label: 'Hidden',
  },
];

export const PAYMENT_METHODS_LABEL = {
  existing_payment: 'Use existing payment',
  add_new_payment: 'Add new payment method',
  use_new_payment: 'Use new payment method',
};

export const FILE_EXTENSION_MAPPING = {
  jpg: 'jpg',
  jpeg: 'jpg',
};

export const SUBSCRIBER_ROLE = [
  {
    key: 'primary',
    label: 'Primary',
  },
  {
    key: 'dependent',
    label: 'Dependent',
  },
];

export const PERMISSIONS_LABEL = {
  onb: 'Stripe Onboarding',
};

export const SUPPORT_TEXT = {
  email:
    'Appears in patient communication and in the patient contact us details',
  phone:
    'Appears in patient communication and in the patient contact us details',
  fee_schedule_desc: 'Savings Summary UI Description',
  plan_summary_desc: 'Plan Summary Description',
  patient_url_part: 'Used for Iframe Prefix',
};
