import React, { useState, useContext } from "react";
import PropTypes from "prop-types";

import CommonDialog from "components/CommonDialog";
import { CompanyDetails } from "components";
import { USER_SERVICES } from "Services";
import { Context } from "context";
import { ADD_NOTIFICATION } from "context/actions";
import Utils from "Shared/Utils";

const CompanyIdentityModal = (props) => {
  const {
    data,
    isOpen,
    closePopup,
    onUpdate,
  } = props;

  const {
    level_data: levelData = {},
    settings: settingsData = {},
    account: accountData = {},
  } = data;

  const {
    partner_id,
    group_id,
    region_id,
    location_id,
    pool_client_id
  } = levelData;
  const {
    company: {
      name: legalName,
    } = {}
  } = accountData;
  const {
    setting_level: level,
    custom_onboard = false,
    fee_schedule_desc = "",
    plan_summary_desc = ""
  } = settingsData;

  const { dispatch: globalDispatch } = useContext(Context);

  const isGroup = level === "group";
  const isRegionLocation = (level === "region" || level === "location");
  const isLocation = level === "location";

  const [companyDetail, setCompanyDetail] = useState({
    companyName: levelData?.name || "",
    companyLegalName: legalName || levelData?.legal_name || "",
    industry: levelData?.industry || "",
    billing_npi: levelData?.billing_npi || "",
    ext_facility_id: levelData?.ext_facility_id || "",
    website: levelData?.plan_website || "",
    patient_website: levelData?.patient_website || "",
    patient_url_part: levelData?.patient_url_part || "",
    addressLine: levelData?.address?.line1 || "",
    addressLine2: levelData?.address?.line2 || "",
    status: levelData?.status || "",
    city: levelData?.address?.city || "",
    state: levelData?.address?.state || "",
    phone: levelData?.phone || "",
    email: levelData?.email || "",
    zipCode: levelData?.address?.postal_code || levelData?.address?.zipcode || "",
    latitude: levelData?.latitude || "",
    longitude: levelData?.longitude || "",
    fee_schedule_desc: fee_schedule_desc,
    plan_summary_desc: plan_summary_desc
  });
  const [checkErrors, setCheckErrors] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [editDetails, setEditDetails] = useState(false);
  const [blurred, setBlurred] = useState({});

  const handleInputChange = (value, attribute, mapUpdates) => {
    if (mapUpdates)
      setCompanyDetail({ ...companyDetail, ...mapUpdates });
    else
      setCompanyDetail({ ...companyDetail, [attribute]: value });

    setEditDetails(true);
  };

  const checkErrorsInForm = async () => {
    if (
      !companyDetail.companyName ||
      !companyDetail.addressLine ||
      !companyDetail.city ||
      !companyDetail.state ||
      !Utils.validateZipCode(companyDetail.zipCode) ||
      !Utils.validateMobile(companyDetail.phone) ||
      !Utils.validateEmail(companyDetail.email) ||
      !Utils.validateWebsite(companyDetail.website)
    ) {
      setCheckErrors(true);
      return true;
    }

    if (isGroup && !companyDetail.fee_schedule_desc) {
      setCheckErrors(true);
      return true;
    }

    if (isGroup && !companyDetail.plan_summary_desc) {
      setCheckErrors(true);
      return true;
    }

    if (!companyDetail.companyLegalName && (custom_onboard || legalName)) {
      setCheckErrors(true);
      return true;
    }

    if (isLocation) {
      if (companyDetail.billing_npi && !Utils.validateNPI(companyDetail.billing_npi)) {
        setCheckErrors(true);
        return true;
      }

      if (!companyDetail.status) {
        setCheckErrors(true);
        return true;
      }

      if (
        (companyDetail.ext_facility_id || custom_onboard) &&
        !Utils.validateFacilityId(companyDetail.ext_facility_id)
      ) {
        setCheckErrors(true);
        return true;
      }
    }


    if(isGroup && (pool_client_id || companyDetail.patient_website)) {
      const validWebsite = Utils.validateWebsite(companyDetail.patient_website);

      if(!validWebsite) {
        setCheckErrors(true);
        return true;
      }
    }

    if (isGroup && !companyDetail?.patient_url_part) {
      setCheckErrors(true);
      setBlurred({ ...blurred, 'patient_url_part': true});
      return true;
    }

    if (!isRegionLocation && !companyDetail.industry) {
      setCheckErrors(true);
      return true;
    }

    setCheckErrors(false);
    return false;
  };

  const onConfirmClick = async () => {
    if (!editDetails) {
      closePopup();
      return;
    }

    let hasErrors = await checkErrorsInForm();
    if (hasErrors)
      return;

    setLoading(true);
    try {
      const payload = {
        [level]: {
          location_id,
          region_id,
          group_id,
          partner_id,
          [`${level}_name`]: companyDetail.companyName,
          legal_name: companyDetail.companyLegalName,
          plan_website: companyDetail.website,
          address: {
            line1: companyDetail.addressLine,
            line2: companyDetail.addressLine2,
            city: companyDetail.city,
            state: companyDetail.state,
            zipcode: companyDetail.zipCode,
          },
          phone: companyDetail.phone,
          email: companyDetail.email,
          country_code: "+1"
        },
      };

      if (!isRegionLocation) {
        payload[level].industry = companyDetail.industry;
      }

      if (isLocation) {
        payload[level].latitude = companyDetail.latitude;
        payload[level].longitude = companyDetail.longitude;
        payload[level].billing_npi = companyDetail.billing_npi;
        payload[level].ext_facility_id = companyDetail.ext_facility_id;
        payload[level].status = companyDetail.status;
      }

      if (isGroup || isLocation) {
        payload['setting'] = {
          fee_schedule_desc: companyDetail.fee_schedule_desc,
          plan_summary_desc: companyDetail.plan_summary_desc
        };
      }

      if (isGroup) {
        payload[level].patient_website = companyDetail.patient_website;
        payload[level].patient_url_part = companyDetail.patient_url_part;
      }

      const response = await USER_SERVICES.updateLevels(payload, level);

      if (response?.type === 'success') {
        dispatchGlobalNotification('success', "Details updated successfully");
        closePopup();
        onUpdate("Settings");
      }
      else {
        throw (response?.message || response?.error);
      }
    } catch (error) {
      dispatchGlobalNotification('error', error || "Error updating the details");
    }
    setLoading(false);
  };

  const dispatchGlobalNotification = (severity, message) =>
    globalDispatch({
      type: ADD_NOTIFICATION,
      payload: { notification: { severity, message } }
    });

  return (
    <CommonDialog
      open={isOpen}
      handleClose={closePopup}
      dialogTitle="Update Company Details"
      primaryBtnTxt="Confirm"
      handlePrimaryButtonClick={onConfirmClick}
      disabledPrimaryButton={isLoading}
      primaryButtonStyles={{ minWidth: 105 }}
      isLoading={isLoading}
      secondaryBtnTxt="Cancel"
      disableSecondaryButton={isLoading}
    >
      <CompanyDetails
        checkErrors={checkErrors}
        companyDetail={companyDetail}
        handleTextChange={handleInputChange}
        level={level}
        data={data}
        legalName={legalName}
        blurred={blurred}
        setBlurred={setBlurred}
      />
    </CommonDialog>
  );
};

CompanyIdentityModal.propTypes = {
  data: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
};

export default CompanyIdentityModal;