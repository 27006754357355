import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem } from "@material-ui/core";
import PropTypes from "prop-types";

import { Maps, PhoneInput, Select, TextField, RoleValidator } from "components";
import { SUPER_ADMIN, SUBSCRIBILI_USER } from "global-constants/roles";
import Utils from "Shared/Utils";
import { formatZipcode, formatNPI, formatFacilityId } from "utils";
import {
  STATES,
  INDUSTRIES,
  STATUS_LIST,
  SUPPORT_TEXT,
} from 'Views/Common/enum';
import {
  emailValidationMessage,
  npiValidationMessage,
  websiteValidationMessage,
  zipcodeValidationMessage,
  facilityIdValidationMessage,
} from "utils/validationMessages";
import { GMAPS_SERVICE } from "Services";
import { Context } from "context";
import styles from "./CompanyDetails.module.scss";

const FIELDS = {
  COMPANY_NAME: 'companyName',
  COMPANY_LEGAL_NAME: 'companyLegalName',
  INDUSTRY: 'industry',
  EMAIL: 'email',
  PHONE: 'phone',
  BILLING_NPI: 'billing_npi',
  FACILITY_ID: 'ext_facility_id',
  WEBSITE: 'website',
  PATIENT_WEBSITE: 'patient_website',
  PREFIX: 'patient_url_part',
  ADDRESS_LINE: 'addressLine',
  ADDRESS_LINE2: 'addressLine2',
  LOCATION_STATUS: 'status',
  CITY: 'city',
  STATE: 'state',
  ZIP_CODE: 'zipCode',
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude',
  FEE_SCHEDULE_DESC: 'fee_schedule_desc',
  PLAN_SUMMARY_DESC: 'plan_summary_desc',
  STATUS: 'status',
};

const CompanyDetails = (props) => {
  const {
    companyDetail,
    handleTextChange,
    checkErrors,
    level,
    data,
    legalName,
    onboardingFlow,
    blurred,
    setBlurred,
  } = props;

  const { level_data: levelData = {}, settings = {} } = data;
  const { custom_onboard = false } = settings;

  const { state: { user } = {} } = useContext(Context);

  const [bounds, setBounds] = useState();
  const [timer, setTimer] = useState();
  const [initialLoad, setInitialLoad] = useState(true);

  const isPartner = level === "partner";
  const isGroup = level === "group";
  const isRegionLocation = (level === "region" || level === "location");
  const isLocation = level === "location";

  const INTERNAL_USER = Utils.checkIfInternalUser(user);

  const renderErrorMessage = (attribute) => {
    switch (attribute) {
      case FIELDS.COMPANY_NAME:
        return 'Company name is required';
      case FIELDS.COMPANY_LEGAL_NAME:
        return 'Legal name is required';
      case FIELDS.BILLING_NPI:
        return npiValidationMessage(companyDetail?.[FIELDS?.BILLING_NPI]);
      case FIELDS.FACILITY_ID:
        return facilityIdValidationMessage(companyDetail?.[FIELDS?.FACILITY_ID]);
      case FIELDS.LOCATION_STATUS:
        return 'Location status is required';
      case FIELDS.INDUSTRY:
        return 'Industry is required';
      case FIELDS.EMAIL:
        return emailValidationMessage(companyDetail?.[FIELDS?.EMAIL]);
      case FIELDS.WEBSITE:
        return websiteValidationMessage(companyDetail?.[FIELDS?.WEBSITE]);
      case FIELDS.PATIENT_WEBSITE:
        return websiteValidationMessage(companyDetail?.[FIELDS?.PATIENT_WEBSITE], 'Patient website');
      case FIELDS.PREFIX:
        return 'Domain Prefix is required'
      case FIELDS.FEE_SCHEDULE_DESC:
        return 'Savings Summary UI Description is required';
      case FIELDS.PLAN_SUMMARY_DESC:
        return 'Plan Summary Description is required'
      case FIELDS.ADDRESS_LINE:
        return 'Address is required';
      case FIELDS.CITY:
        return 'City is required';
      case FIELDS.STATE:
        return 'State is required';
      case FIELDS.ZIP_CODE:
        return zipcodeValidationMessage(companyDetail?.[FIELDS?.ZIP_CODE]);
      default:
        return '';
    }
  };

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);

      return;
    }

    const constructedAddress = [
      companyDetail?.[FIELDS?.ADDRESS_LINE],
      companyDetail?.[FIELDS?.ADDRESS_LINE2],
      companyDetail?.[FIELDS?.CITY],
      companyDetail?.[FIELDS?.STATE],
      companyDetail?.[FIELDS?.ZIP_CODE],
    ]
      .join(' ')
      .trim();

    if (constructedAddress) {
      clearTimeout(timer);
      setTimer(setTimeout(() => getGeocode(constructedAddress), 1000 * 2));
    }
  }, [
    companyDetail[FIELDS.ADDRESS_LINE],
    companyDetail[FIELDS.ADDRESS_LINE2],
    companyDetail[FIELDS.CITY],
    companyDetail[FIELDS.STATE],
    companyDetail[FIELDS.ZIP_CODE],
  ]);

  const getGeocode = async (constructedAddress) => {
    try {
      const { data = [] } = await GMAPS_SERVICE.getGeocode(constructedAddress);

      const location = data[0]?.geometry?.location || {};

      if (Object.keys(location)?.length) {
        setLocation({
          latitude: location.lat,
          longitude: location.lng
        });

        setBounds(location);
      }
    }
    catch (err) {
      console.log(err);
    }
  };

  const getErrorMessages = (attribute, required) => {
    if (!checkErrors || !required) return "";

    if (attribute === FIELDS.PATIENT_WEBSITE) {
      if (!levelData?.pool_client_id && !companyDetail[FIELDS.PATIENT_WEBSITE])
        return;

      return renderErrorMessage(attribute);
    } else if (attribute === FIELDS.PREFIX) {
      if (companyDetail[FIELDS.PREFIX]) return;

      return renderErrorMessage(attribute);
    } else if (attribute === FIELDS.BILLING_NPI) {
      if (!companyDetail[FIELDS.BILLING_NPI]) return;

      return renderErrorMessage(attribute);
    } else if (attribute === FIELDS.FEE_SCHEDULE_DESC) {
      if(companyDetail[FIELDS.FEE_SCHEDULE_DESC]) return;

      return renderErrorMessage(attribute);
    } else if (attribute === FIELDS.PLAN_SUMMARY_DESC) {
      if(companyDetail[FIELDS.PLAN_SUMMARY_DESC]) return;

      return renderErrorMessage(attribute);
    }
    else if (attribute === FIELDS.FACILITY_ID) {
      if (companyDetail[FIELDS.FACILITY_ID] || custom_onboard)
        return renderErrorMessage(attribute);

      return "";
    } else if (
      attribute === FIELDS.WEBSITE ||
      attribute === FIELDS.ZIP_CODE ||
      attribute === FIELDS.EMAIL
    ) {
      return renderErrorMessage(attribute);
    }

    if (!_.get(companyDetail, `${[attribute]}`))
      return renderErrorMessage(attribute);
  };

  const getSupportText = (attribute) => {
    if (attribute === FIELDS.EMAIL) return SUPPORT_TEXT.email;
    else if (attribute === FIELDS.FEE_SCHEDULE_DESC)
      return SUPPORT_TEXT?.fee_schedule_desc;
    else if (attribute === FIELDS.PLAN_SUMMARY_DESC)
      return SUPPORT_TEXT?.plan_summary_desc;
    else if (attribute === FIELDS.PREFIX) return SUPPORT_TEXT?.patient_url_part;

    return '';
  };

  const renderInput = (name, label, value, required = true, disabled = false, textFieldProps = {}) => {
    const errorMessage = getErrorMessages(name, required);
    const supportText = getSupportText(name);

    return (
      <TextField
        fullWidth
        required={required}
        name={name}
        value={value}
        label={label}
        disabled={disabled}
        onChange={(e) => {
          setBlurred({ ...blurred, [name]: false});
          handleTextChange(e.target.value, name);
        }}
        onBlur={() => setBlurred({ ...blurred, [name]: true})}
        variant="outlined"
        error={blurred[name] && Boolean(errorMessage)}
        helperText={(blurred[name] && errorMessage) || supportText}
        inputProps={{
          'data-testid': `company-details-${name}`
        }}
        {...textFieldProps}
      />
    );
  };

  const invalidPhoneError = !Utils.validateMobile(
    companyDetail?.[FIELDS?.phone],
  )
    ? 'Enter valid phone number'
    : '';
  const phoneFieldError = !companyDetail?.[FIELDS?.phone]
    ? "Phone is required"
    : invalidPhoneError;

  const setLocation = ({ latitude, longitude }) => handleTextChange(null, null, { latitude, longitude });

  const industryErrorMsg = getErrorMessages(FIELDS.INDUSTRY);
  const stateErrorMsg = getErrorMessages(FIELDS.STATE);
  const locationStatusErrorMsg = getErrorMessages(FIELDS.LOCATION_STATUS);

  let gridSpace = 6;
  let gridSpaceShort = undefined;

  if (isPartner && !INTERNAL_USER && !onboardingFlow) {
    gridSpace = 4;
  } else if (isGroup || isRegionLocation) {
    if (INTERNAL_USER) {
      gridSpaceShort = 4;
    } else if (!onboardingFlow) {
      gridSpace = 4;
    }
  }

  return (
    <Grid container spacing={3} alignItems="stretch" id="company-details">
      <Grid
        item
        xs={12}
        sm={isLocation && !onboardingFlow ? 4 : gridSpaceShort || gridSpace}
      >
        {renderInput(
          FIELDS.COMPANY_NAME,
          "Company Name",
          companyDetail[FIELDS.COMPANY_NAME]
        )}
      </Grid>

      {!onboardingFlow && (
        <Grid item xs={12} sm={isLocation ? 4 : gridSpaceShort || gridSpace}>
          {renderInput(
            FIELDS.COMPANY_LEGAL_NAME,
            "Legal Name",
            companyDetail[FIELDS.COMPANY_LEGAL_NAME],
            Boolean(custom_onboard || legalName),
          )}
        </Grid>
      )}

      {isLocation && !onboardingFlow && (
        <Grid item xs={12} sm={4}>
          <FormControl
            required
            variant="outlined"
            error={Boolean(locationStatusErrorMsg)}
            fullWidth
          >
            <InputLabel>Location Status</InputLabel>
            <Select
              labelId="company-detail-select-status-outlined-label"
              id="company-detail-select-status-outlined"
              label="Location Status *"
              name={FIELDS?.STATUS}
              value={companyDetail?.[FIELDS?.STATUS]}
              onChange={(e) =>
                handleTextChange(e.target.value, FIELDS.LOCATION_STATUS)
              }
            >
              <MenuItem value="">Location Status</MenuItem>
              {STATUS_LIST.map((indState, i) => (
                <MenuItem
                  key={indState.key}
                  value={indState.label}
                  data-testid={`company-location-status-select-item-${i}`}
                >
                  {indState.label}
                </MenuItem>
              ))}
            </Select>
            {locationStatusErrorMsg && (
              <FormHelperText>Location status is required</FormHelperText>
            )}
          </FormControl>
        </Grid>
      )}

      {!isRegionLocation && (
        <RoleValidator roles={[SUPER_ADMIN, SUBSCRIBILI_USER]}>
          <Grid item xs={12} sm={gridSpaceShort || gridSpace}>
            <FormControl
              required
              variant="outlined"
              error={Boolean(industryErrorMsg)}
              fullWidth
            >
              <InputLabel>Select Industry</InputLabel>
              <Select
                labelId="company-detail-select-industry-outlined-label"
                id="company-detail-select-industry-outlined"
                label="Select Industry *"
                name={FIELDS.INDUSTRY}
                value={companyDetail[FIELDS.INDUSTRY]}
                onChange={(e) =>
                  handleTextChange(e.target.value, FIELDS.INDUSTRY)
                }
              >
                <MenuItem value="">Select Industry</MenuItem>
                {INDUSTRIES.map((industry, i) => (
                  <MenuItem
                    key={industry}
                    value={industry}
                    data-testid={`company-industry-select-item-${i}`}
                  >
                    {industry}
                  </MenuItem>
                ))}
              </Select>
              {industryErrorMsg && (
                <FormHelperText>Industry is required</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </RoleValidator>
      )}

      <Grid
        item
        xs={12}
        sm={isLocation && !onboardingFlow ? 4 : gridSpaceShort || gridSpace}
      >
        {renderInput(FIELDS.WEBSITE, "Website", companyDetail[FIELDS.WEBSITE])}
      </Grid>

      {isLocation && !onboardingFlow && (
        <>
          <Grid item xs={12} sm={4}>
            {renderInput(
              FIELDS.BILLING_NPI,
              "Billing NPI",
              companyDetail[FIELDS.BILLING_NPI],
              false,
              false,
              {
                onInput: (e) => (e.target.value = formatNPI(e.target.value)),
              }
            )}
          </Grid>

          <Grid item xs={12} sm={isLocation && !onboardingFlow ? 4 : gridSpace}>
            {renderInput(
              FIELDS.FACILITY_ID,
              "Clearinghouse Facility ID",
              companyDetail[FIELDS.FACILITY_ID],
              Boolean(custom_onboard),
              false,
              {
                onInput: (e) =>
                  (e.target.value = formatFacilityId(e.target.value)),
              }
            )}
          </Grid>
        </>
      )}

      {isGroup && (
        <RoleValidator roles={[SUPER_ADMIN, SUBSCRIBILI_USER]}>
          <Grid item xs={12} sm={gridSpaceShort || gridSpace}>
            {renderInput(
              FIELDS.PATIENT_WEBSITE,
              "Patient Website",
              companyDetail[FIELDS.PATIENT_WEBSITE],
            )}
          </Grid>
        </RoleValidator>
      )}

      {isGroup && (
        <RoleValidator roles={[SUPER_ADMIN, SUBSCRIBILI_USER]}>
          <Grid item xs={12} sm={gridSpaceShort || gridSpace}>
            {renderInput(
              FIELDS.PREFIX,
              "Domain Prefix",
              companyDetail[FIELDS.PREFIX],
            )}
          </Grid>
        </RoleValidator>
      )}
      {(isGroup || isLocation) && !onboardingFlow && (
        <Grid item xs={12} sm={12}>
          {renderInput(
            FIELDS.FEE_SCHEDULE_DESC,
            'Savings Summary Description',
            companyDetail[FIELDS.FEE_SCHEDULE_DESC],
          )}
        </Grid>
      )}
      {(isGroup || isLocation) && !onboardingFlow && (
        <Grid item xs={12} sm={12}>
          {renderInput(
            FIELDS.PLAN_SUMMARY_DESC,
            'Plan Summary Description',
            companyDetail[FIELDS.PLAN_SUMMARY_DESC],
          )}
        </Grid>
      )}

      <Grid container spacing={3} className={styles.addressWrapper}>
        <Grid item xs={12} sm={6}>
          {renderInput(
            FIELDS.ADDRESS_LINE,
            "Address Line 1",
            companyDetail[FIELDS.ADDRESS_LINE]
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          {renderInput(
            FIELDS.ADDRESS_LINE2,
            "Address Line 2",
            companyDetail[FIELDS.ADDRESS_LINE2],
            false
          )}
        </Grid>

        <Grid item xs={12} sm={4}>
          {renderInput(FIELDS.CITY, "City", companyDetail[FIELDS.CITY])}
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl
            required
            variant="outlined"
            error={Boolean(stateErrorMsg)}
            fullWidth
          >
            <InputLabel>Select State</InputLabel>
            <Select
              labelId="company-detail-select-state-outlined-label"
              id="company-detail-select-state-outlined"
              label="Select State *"
              name={FIELDS?.STATE}
              value={companyDetail?.[FIELDS?.STATE]}
              onChange={(e) => handleTextChange(e.target.value, FIELDS.STATE)}
            >
              <MenuItem value="">Select State</MenuItem>
              {STATES &&
                STATES.map((indState, i) => (
                  <MenuItem
                    key={indState.code}
                    value={indState.code}
                    data-testid={`company-state-select-item-${i}`}
                  >
                    {indState.name}
                  </MenuItem>
                ))}
            </Select>
            {stateErrorMsg && (
              <FormHelperText>State is required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          {renderInput(
            FIELDS.ZIP_CODE,
            "Zip Code",
            companyDetail[FIELDS.ZIP_CODE],
            true,
            false,
            {
              onInput: (e) => {
                e.target.value = formatZipcode(e.target.value);
              },
            }
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl variant="outlined" fullWidth>
          <PhoneInput
            id="company-phone-number"
            isValid={!(checkErrors && blurred.phone && phoneFieldError)}
            error={checkErrors && blurred.phone && phoneFieldError}
            countryCodeEditable={false}
            value={companyDetail?.[FIELDS?.PHONE]}
            onChange={(value) => {
              setBlurred({
                ...blurred,
                phone: false,
              });
              handleTextChange(value, FIELDS?.PHONE);
            }}
            onBlur={() => setBlurred({ ...blurred, phone: true })}
          />
          <FormHelperText
            className={
              checkErrors && blurred.phone && phoneFieldError
                ? 'color_error'
                : ''
            }
          >
            {(checkErrors && blurred.phone && phoneFieldError) ||
              SUPPORT_TEXT?.phone}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        {renderInput(FIELDS.EMAIL, "Support Email", companyDetail[FIELDS.EMAIL])}
      </Grid>

      {isLocation && !onboardingFlow && (
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Box className="mapsWrapper">
                <Maps
                  bounds={bounds}
                  latitude={companyDetail?.[FIELDS?.LATITUDE]}
                  longitude={companyDetail?.[FIELDS?.LONGITUDE]}
                  setLocation={setLocation}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {renderInput(
                    FIELDS.LATITUDE,
                    "Latitude",
                    companyDetail?.[FIELDS.LATITUDE],
                    true,
                    true
                  )}
                </Grid>

                <Grid item xs={12}>
                  {renderInput(
                    FIELDS.LONGITUDE,
                    "Longitude",
                    companyDetail?.[FIELDS.LONGITUDE],
                    true,
                    true
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

CompanyDetails.propTypes = {
  companyDetail: PropTypes.object.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  checkErrors: PropTypes.bool.isRequired,
  level: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onboardingFlow: PropTypes.bool,
  groupSettings: PropTypes.object,
  blurred: PropTypes.object,
  setBlurred: PropTypes.object,
};

export default CompanyDetails;
